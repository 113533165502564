.grm-disabled-link {
    pointer-events: none;
    opacity: 0.6;
    text-decoration: none;
}

.grm-custom-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.25);
    
}
.grm-custom-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    background-color: rgba(0,0,0, 1);
}

.grm-custom-overlay-front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// authentication pages background
body.authentication-bg-success {
    background-color: #008C95;
    background-size: cover;
    background-position: center;
}
.swiper-button-next, .swiper-button-prev {
    color: #FFA500 !important;
}
  
.bg-360 {
    background-color: #FFA500;
}
.bg-360-light {
    background-color: #ffe5b6;
}
.bg-360-gray {
    background-color: #f3f7f9!important;
}